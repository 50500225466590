import * as Sentry from "@sentry/browser";
import {BrowserTracing} from "@sentry/tracing";

const userEmail = document.body.getAttribute("data-user-email");
const username = document.body.getAttribute("data-user-name");

Sentry.setUser({
    email: userEmail ?? "Unknown user email",
    username: username ?? "Unknown username",
});

if (process.env.PRODUCTION) {
Sentry.init({
     dsn: process.env.SENTRY_DSN_FRONTEND,
     ignoreErrors: [
          'AbortError',
          'ResizeObserver loop completed with undelivered notifications.',
          /^ReferenceError: Can't find variable: msDiscoverChatAvailable$/,
          /^UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:\d+/,
          /^TypeError: Failed to fetch$/,
          /^TypeError: Load failed$/,
          /^TypeError: NetworkError when attempting to fetch resource\.$/,
          /^TypeError: Cannot read properties of undefined \(reading '_avast_submit'\)$/,
     ],
     integrations: [new BrowserTracing()],
        environment: "prod"
        // tracesSampleRate: 1.0,
});
}